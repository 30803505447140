import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { sortedUniq, compact } from 'lodash'
import { AiOutlineCalendar } from '@react-icons/all-files/ai/AiOutlineCalendar'
import { AiOutlineEnvironment } from '@react-icons/all-files/ai/AiOutlineEnvironment'
import { AiOutlineInfoCircle } from '@react-icons/all-files/ai/AiOutlineInfoCircle'
import styled from '@emotion/styled'

import Page from '../templates/Page'
import EventsData from '../images/events-data.json'
import { Button, colors, breakpoints } from '../utils/styles'

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 2.5rem;
  justify-content: space-around;
  margin-bottom: 5rem;

  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Event = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const EventLine = styled.span`
  display: flex;
  align-items: center;

  & > :first-of-type {
    margin-right: 0.5rem;
  }

  a {
    color: black;
    text-decoration: none;
  }
`

const months = compact(sortedUniq(EventsData.map(({ month }) => month)))

const eventsInMonths = []
months.forEach(searchedMonth => {
  eventsInMonths[searchedMonth] = [
    ...EventsData.filter(({ month }) => month === searchedMonth),
  ]
})

const EventsPage = ({ data: { heroImage } }) => {
  const { t } = useTranslation()
  return (
    <Page title={'NIGHTTEC'} subtitle={t('AT EVENTS')} headerImage={heroImage}>
      {months.map(month => (
        <React.Fragment key={month}>
          <h3>
            <Trans>{month}</Trans>
          </h3>
          <EventsGrid>
            {eventsInMonths[month].map(event => (
              <Event key={event.name}>
                <h4>{event.name}</h4>

                <div>
                  <EventLine>
                    <AiOutlineCalendar size={22} />
                    {new Date(Date.parse(event.startDate)).toLocaleDateString()}
                    {event.startDate !== event.endDate &&
                      ` - ${new Date(
                        Date.parse(event.endDate)
                      ).toLocaleDateString()}`}
                  </EventLine>

                  <EventLine>
                    <AiOutlineEnvironment size={22} />
                    <a
                      href={event.locationUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {event.location}
                    </a>
                  </EventLine>

                  <EventLine>
                    <AiOutlineInfoCircle size={22} />
                    <a href={event.website} target="_blank" rel="noreferrer">
                      Details
                    </a>
                  </EventLine>
                </div>
                <br />

                <Button
                  as="a"
                  backgroundColor={colors.androidGreen}
                  href={`/rental#${encodeURI(event.name)}`}
                  style={{ width: '2rem' }}
                >
                  <Trans>Rent</Trans>
                </Button>
              </Event>
            ))}
          </EventsGrid>
        </React.Fragment>
      ))}
    </Page>
  )
}

export const query = graphql`
  query ($language: String!) {
    heroImage: file(
      name: { eq: "Events_n" }
      sourceInstanceName: { eq: "images" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          aspectRatio: 4
        )
      }
    }
    i18nLocales: allLocale(
      filter: { ns: { in: ["common", "events"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default EventsPage
